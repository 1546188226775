@import '../../../assets/variable.scss';
.highlight {
  color: $action-primary;
}
.loader-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
  width: 100%;
}
.table-main {
  position: relative;
  .table-head-content {
    // position: absolute;
    // top: 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 30px 0px 30px;
    .select-main {
      display: flex;
      align-items: center;
      gap: 0 17px;
    }
    .select-text {
      color: $black-color40;
    }
    .search-otr {
      position: relative;
      display: flex;
      align-items: center;
      gap: 0 30px;
      flex: 1;
      justify-content: space-between;
      .search-input-otr {
        flex: 1;
      }
      .search-otr-wrapper {
        width: 330px;
      }
      .action {
        display: flex;
      }
    }
  }
  .table-otr {
    padding-top: 10px;
    padding-inline: 30px;
  }
  .table-responsive {
    overflow-x: auto;
  }

  .table-rows {
    background: #ffffff;
    box-shadow: 0px 0.3em 0.3em rgba(0, 0, 0, 0.1) !important;
  }
  .search-result {
    // position: absolute;
    // transform: translateY(-20px);
    .search-value {
      color: $action-primary;
    }
  }
  .table-rows th {
    height: 65px;
    vertical-align: middle;
    border: 0px;
    padding-left: 25px !important;
    padding-right: 5px !important;
    white-space: nowrap;
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 14px;
    line-height: 28px;
    color: #0a0a0a;
  }

  .table-rows tr {
    border-top: 1px solid rgba(0, 70, 67, 0.15) !important;
  }

  .table-header tr {
    border-top: none !important;
  }

  .table-rows td {
    height: 81px;
    vertical-align: middle;
    border: 0px;
    padding-left: 25px !important;
    padding-right: 5px !important;
    white-space: nowrap;
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 14px;
    line-height: 28px;
    color: #0a0a0a;
    opacity: 0.65;
  }

  .status {
    padding: 4px;
    gap: 16px;
    text-align: center;
  }

  .status.active {
    background: #dcfce7;
    color: #22c55e;
  }

  .status.pending {
    background: #fef9c3;
    color: #eab308;
  }

  .status.Enrolled {
    background: #dcfce7;
    color: #22c55e;
  }

  .status.Excluded {
    background: #fee2e2;
    color: #ef4444;
  }

  .status.inactive {
    background: #fee2e2;
    color: #ef4444;
  }

  .form-check-input {
    border-radius: 0% !important;
  }
  .footer-table {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 0px;

    .css-bs1igt-ValueContainer {
      padding: 0 12px;
    }
    .css-j18abh-IndicatorsContainer {
      padding: 0;
    }

    .entity-text {
      color: $black-color40;
    }
    .pagination li {
      &:first-child {
        margin-right: 8px;
      }
      &:last-child {
        margin-left: 8px;
      }
      &:not(.disabled) {
        cursor: pointer;
      }
    }
    .icon-otr {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      border: none;
      background-color: $black-color40;
      transition: 0.3;
      margin-bottom: 0;
      &:hover {
        background-color: $action-primary;
        svg {
          color: $white-color;
        }
      }
      svg {
        color: $black-color40;
      }
    }
    .rc-pagination-item-link,
    .page-linkk-otr {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      border: none;
      background-color: transparent;
      box-shadow: none;
      .page-linkk {
        color: $black-color40;
      }
    }
    .rc-pagination-item-link:before {
      content: '...';
    }
    .rc-pagination-item-active {
      .page-linkk-otr {
        background-color: $action-primary;
        box-shadow: none;
        .page-linkk {
          color: $white-color;
        }
      }
    }
  }
}

.input-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
}

.caret-down-icon {
  position: absolute;
  top: 67%;
  right: 2rem;
  transform: translateY(-46%);
  pointer-events: none;
}

@media (max-width: 992px) {
  .table-main {
    .table-otr {
      // padding-top: 120px;
    }
    .table-head-content {
      padding-block: 0 !important;
      .search-otr {
        flex-direction: column;
        .search-input-otr {
          min-width: 100%;
          .search-otr-wrapper {
            min-width: 100%;
            padding: 5px;
            .input-otr {
              margin: 0 !important;
              gap: 0 !important;
              .search-icon {
                margin-top: 10px;
              }
            }
          }
        }
        .button-container {
          width: 100%;
          button {
            flex-grow: 1;
            margin: 5px 5px !important;
          }
        }
      }
    }
  }
}

.px-0 {
  max-height: 600px;
  overflow-y: auto;
}
