@import '../../assets/variable.scss';

.loader-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
  width: 100%;
}
.state-card {
  padding: 16px 24px;
  background: #ffffff;
  box-shadow: 0px 16px 48px rgba(0, 0, 0, 0.08);
  width: 100%;
}

.clr {
  color: #00b5d0;
}

.f-w {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 52px;
  color: #0a0a0a;
}

.dropdown {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #0a0a0a;
  opacity: 0.45;
  height: 40px;
  background: rgba(10, 10, 10, 0.05);
}

.card {
  --bs-card-border-width: 0;
  --bs-card-border-radius: none;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: none;
  --bs-card-cap-padding-y: 0;
  --bs-card-cap-padding-x: 1;
  --bs-card-cap-bg: ;
}

.progress {
  --bs-progress-height: 2.8rem;
  --bs-progress-font-size: 16;
  --bs-progress-bg: #e9ecef;
  --bs-progress-border-radius: none;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: start;
  overflow: hidden;
  background-color: #b6e3ea;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  color: #0a0a0a;
}

.progress-percent {
  display: flex;
  align-items: center;
  padding-right: 8px;
}

.progress-width {
  width: 100%;
}
@media (max-width: 480px) {
  .state-card-container {
    width: 100% !important;
  }
}
