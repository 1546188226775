@import '../../assets/variable.scss';

.survey {
  .progress-bar {
    position: relative;
    display: flex;
    width: 100%;
    height: 10px;
    background-color: rgba(10, 10, 10, 0.15);

    .progress {
      position: absolute;
      left: 0;
      top: 0;
      background-color: $action-primary;
      height: 10px;
    }
  }
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 80vh;
  margin: auto;
  .question-card {
    padding-bottom: 120px !important;

    width: 100%;
    // height: 100%;
    background-color: $white-color;
    position: relative;
    .previous-btn {
      position: absolute;
      bottom: 40px;
      left: 40px;
    }
    .next-btn {
      position: absolute;
      bottom: 40px;
      right: 40px;
    }
  }
  .review-action {
    display: flex;
    justify-content: space-between;
    margin-top: 4rem;
  }
  .questions-overview {
    .table-responsive {
      max-height: 500px;
      overflow: auto;
      table {
        table-layout: fixed;
      }

      th:nth-child(1) {
        width: 5%;
      }
      th:nth-child(2) {
        width: 45%;
      }
      th:nth-child(3) {
        width: 45%;
      }
      th:nth-child(4) {
        width: 5%;
      }
      td:nth-child(2) {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      td:nth-child(2) {
        max-width: 60%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      td:nth-child(3) {
        max-width: 30%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      td:nth-child(4) {
        text-align: center;
      }
    }
  }
}

.Enrolled {
  background: #dcfce7;
  color: #22c55e;
}

.pending {
  background: #fef9c3;
  color: #eab308;
}
.Excluded {
  background: #fee2e2;
  color: #ef4444;
}

.status.active {
  background: #dcfce7;
  color: #22c55e;
}

.status.inactive {
  background: #fee2e2;
  color: #ef4444;
}

.status {
  padding: 4px;
  gap: 16px;
  width: max-content;
}

.border-top {
  border: 1px solid #ced4da;
}

.main-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 5px;
}

.arrow-and-content {
  display: flex;
  align-items: center;
}

.card-container {
  display: flex;
  // align-items: flex-start;
}

.main-card {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-right: 1rem;
  max-width: 100%;
}

.expand-card {
  background: rgba(10, 10, 10, 0.1);
}

.card-body {
  padding: 0 !important;
}

.body-contents {
  display: flex;
}

.survey-info {
  flex-basis: 28%;
  padding-left: 4rem;
  // padding-top: 6rem;
}

.space {
  margin-right: 8rem;
  white-space: nowrap;
  position: relative;
}

.text {
  color: var(--tailwind-css-neutral-950, #0a0a0a);
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 30.4px;
}

.btn-1 p {
  background: rgba(10, 10, 10, 0.1);
  padding: 2px 3px;
}

.btn-2 p {
  background: rgba(10, 10, 10, 0.1);
  padding: 2px 3px;
  width: fit-content;
}
.link-button {
  color: #0a0a0a;
  position: relative;
  .link-arrow-right {
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.5s;
  }
  .link-arrow-left {
    position: absolute;
    right: 100%;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.5s;
  }
  &:hover {
    color: #a2a2a2 !important;
    .link-arrow-right {
      transform: translateY(-50%) translateX(50%);

      transition: 0.5s;
    }
    .link-arrow-left {
      transform: translateY(-50%) translateX(-50%);

      transition: 0.5s;
    }
  }
}
@media (max-width: $small-screen) {
  .survey {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80vh;
    margin: auto;
    .question-card {
      padding: 10px 20px;

      width: 100%;
      // height: 100%;
      background-color: $white-color;
      .previous-btn {
        position: absolute;
        bottom: 20px;
        left: 10px;
      }
      .next-btn {
        position: absolute;
        bottom: 20px;
        right: 10px;
      }
    }
  }
}

.survey-history {
  display: flex;
  justify-content: space-between;
}

.main-btn {
  display: flex;
  justify-content: end;
  align-items: center;
  text-align: center;
}

.main-questioncard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 800px) {
  .main-header {
    flex-direction: column;
    align-items: start;
  }

  .arrow-and-content {
    margin-bottom: 10px;
  }

  .survey-btn {
    align-self: flex-start;
  }
}

@media (max-width: 800px) {
  .body-contents {
    display: flex;
    flex-direction: column;
    align-items: start !important;
  }

  .body-contents .ms-5 {
    margin-left: 1rem !important;
  }

  .survey-history {
    display: flex;
    flex-direction: column;
  }

  .main-btn {
    display: flex;
    justify-content: start;
  }

  .main-questioncard {
    display: flex;
    flex-direction: column;
    justify-content: start;
  }

  .main-questioncard p {
    padding-right: 9rem;
  }

  .main-questioncard button {
    padding-right: 3.4rem;
  }

  .link-button .link-arrow-right {
    position: absolute;
    left: 69%;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.5s;
  }
}

@media (max-width: 596px) {
  .card-container {
    display: flex;
    flex-direction: column;
  }

  .main-header {
    order: -1;
    padding-bottom: 1rem !important;
  }
  .main-container {
    flex-direction: column;
  }
  .survey-info {
    order: 2;
    padding: 0 !important;
    background: rgba(10, 10, 10, 0.1);
    width: 100%;
    margin-bottom: 1rem;
    flex-basis: 100%;
  }

  .main-card {
    order: 3;
    margin-right: 0;
  }

  .survey-info h5 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 2rem;
    padding-top: 1.5rem;
    padding-bottom: 1rem;
    padding-right: 1rem;
  }

  .survey-space {
    padding-left: 1.4rem;
  }

  .survey-btn {
    padding-left: 2rem;
  }

  .line {
    border: 1px solid rgba(10, 10, 10, 0.1);
  }

  .outreach-space{
    padding-left: 1.4rem;
  }
}

.notes-text-area {
  background-color: white;
  padding:8px;
  width: 100%;
  &:focus {
    outline: none;
    border: 1px solid $action-primary;
  }
  
}
@media (max-width: 970px) {
  .review-action {
    display: flex;
    flex-direction: column;
  }

  .surveyreview-btn {
    display: flex;
    flex-direction: column;
  }

  .surveyreview-btn button {
    margin-bottom: 1rem;
    width: 100%;
  }
}
