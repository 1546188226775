@import '../../../assets/variable.scss';
.cursor-pointer {
  cursor: pointer;
}

.feild-wrapper {
  position: relative;
}
.input-otr {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px 0;
  margin: 10px 0 15px 0;
  width: auto !important;
  position: relative;
}
.error-message {
  position: absolute;
  top: 100%;
  // transform: translateY(-100%);
  font-size: 12px;
  color: red;
}
.input-label {
  font-size: $font-size-xs;
  line-height: $line-height-sm;
  font-family: 'Open Sans';
  font-weight: 700;
  color: $gray-color4;
  opacity: 0.65;
}

.theme-input {
  font-size: $font-size-sm;
  line-height: $line-height-sm;
  font-family: 'Open Sans';
  font-weight: 400;
  color: $gray-color4;
  background-color: transparent;
  border: 1px solid #ced4da;
  padding: 8px 28px;
  border-radius: 0px;
  position: relative;
  z-index: 10;
  transition: 0.3s;

  &:hover {
    border: 1px solid #0a0a0a50;
    outline: none;
  }
  &:focus {
    border: 1px solid $action-primary;
    outline: none;
  }

  &:focus::placeholder {
    opacity: 0;
  }
  &::placeholder {
    color: $gray-color4;
    transition: 0.3s;
    opacity: 0.45;
  }
}

.mif-otr {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  align-items: flex-start;
}

.mif-otr label {
  font-size: $font-size-xs;
  line-height: $line-height-sm;
  font-family: 'Open Sans';
  font-weight: 700;
  color: $gray-color4;
  opacity: 0.65;
}

.select-wrapper {
  flex-grow: 1;
}

.mif-otr select {
  width: 100%;
  padding: 11px 28px;
}

.enrollment-otr {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  align-items: flex-start;
  justify-self: end;
}

.enrollment-otr label {
  font-size: $font-size-xs;
  line-height: $line-height-sm;
  font-family: 'Open Sans';
  font-weight: 700;
  color: $gray-color4;
  opacity: 0.65;
}

.select-wrapper {
  flex-grow: 1;
}

.enrollment-otr select {
  width: 100%;
  padding: 11px 28px;
}
