@import '../../../assets/variable.scss';

.css-1rs26gt:hover {
  background-color: inherit !important;
  color: inherit !important;
}

.async-select,
.table-assigned-provider .search-otr {
  flex: 1;
}

.custom-menu-option {
  cursor: pointer;
  .text-danger {
    color: $action-danger !important;
  }
  &:hover {
    background: #0a0a0a09;
  }
}
.input-otr {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px 0;
  margin: 10px 0 15px 0;
  width: auto !important;
  position: relative;
}
.error-message {
  position: absolute;
  top: 100%;
  // transform: translateY(-100%);
  font-size: 12px;
  color: red;
}


.icons{
  position: relative;
  top: 2rem;
}
