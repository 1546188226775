@import '../../../assets/variable.scss';

.header-main {
  padding: 20px 0;
  .dropdown {
    width: auto !important;
    background-color: transparent !important;
    .dropdown-toggle::after {
      display: none !important;
    }
    button:focus:not(:focus-visible) {
      border: none !important;
    }
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 30px;
    .breadcrumb {
      margin: 0;
    }
    .profile-otr {
      display: flex;
      align-items: center;
      gap: 0 12px;
      cursor: pointer;
      .avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $gray-color;
      }
      .user-name {
        color: $black-color65;
        overflow: hidden;
        font-weight: 600;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
  }
}
.dropdown {
  width: auto !important;
  background-color: transparent !important;
  .dropdown-toggle::after {
    display: none !important;
  }
  button:focus:not(:focus-visible) {
    border: none !important;
  }
}
.profile-otr {
  display: flex;
  align-items: center;
  gap: 0 12px;
  cursor: pointer;
  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $gray-color;
  }
  .user-name {
    color: $black-color65;
    overflow: hidden;
    font-weight: 600;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}
@media (max-width: 768px) {
  .header-main {
    padding-block: 0;
  }
  .Dropdown {
    bottom: 6rem;
    left: 1.4rem;
  }

  .breadcrumb {
    margin: 0;
    margin-top: 2rem !important;
  }
}
