@import '../../../assets/variable.scss';

.logo {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .title {
    color: $action-primary;
  }
  .sub-title {
    color: $gray-color4;
    line-height: 18px !important;
  }
}

.right-menu {
  height: 40%;
}

.header-card {
  .icon {
    width: 30px;
    height: 30px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    border: 1px solid #ced4da;
  }
  .title {
    opacity: 0.45;
  }
  .sub-title {
    opacity: 0.65;
  }
}
.right-dropdown {
  display: none;
}
@media (max-width: 1080px) {
  .right-menu {
    display: none !important;
  }
  .right-dropdown {
    display: block;
    position: relative;
    .survey-dropdown {
      position: absolute;
      width: 227px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      right: 0;
      z-index: 100;
      background-color: $white-color;
      padding: 10px 28px;
    }
  }
}
