@import '../../../assets/variable.scss';
.cursor-pointer {
  cursor: pointer;
}
.input-otr {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px 0;
  margin: 10px 0;
  width: auto !important;
  position: relative;
}
.error-message {
  position: absolute;
  top: 100%;
  // transform: translateY(-100%);
  font-size: 12px;
  color: red;
}
.input-label {
  font-size: $font-size-xs;
  line-height: $line-height-sm;
  font-family: 'Open Sans';
  font-weight: 700;
  color: $gray-color4;
  opacity: 0.65;
}

.theme-input {
  font-size: $font-size-sm;
  line-height: $line-height-sm;
  font-family: 'Open Sans';
  font-weight: 400;
  color: $gray-color4;
  background-color: transparent;
  border: 1px solid #ced4da;
  padding: 8px 28px;
  border-radius: 0px;
  position: relative;
  z-index: 10;
  transition: 0.3s;

  &:hover {
    border: 1px solid #0a0a0a50;
    outline: none;
  }
  &:focus {
    border: 1px solid $action-primary;
    outline: none;
  }

  &:focus::placeholder {
    opacity: 0;
  }
  &::placeholder {
    color: $gray-color4;
    transition: 0.3s;
    opacity: 0.45;
  }
}
.border-active {
  border: 1px solid $action-primary;
  outline: none;
}
.rdt {
  width: 100%;
  .rdtPicker {
    padding: 20px 20px 5px 20px;
    min-width: 300px;
    box-shadow: 0 0px 5px rgba(0, 0, 0, 0.2);
    border: 1px solid #00000019;
    font-family: 'Open Sans';

    .rdtDays,
    .rdtMonths,
    .rdtYears {
      // background: green;

      .rdtPrev {
        background: #f3f3f3;
        border-radius: 50%;
        position: relative;
        padding: 20px;

        span {
          display: none;
        }
        &::before {
          content: '➔';
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotateY(180deg);
          color: #a4a4a4;
          font-size: 20px;
          font-weight: 300;
          padding: 5px;
        }
      }
      .rdtSwitch {
        color: #606060;
        font-size: 1.1rem;
        font-weight: 700;
      }

      .rdtNext {
        background: #f3f3f3;
        border-radius: 50%;
        position: relative;
        padding: 20px;

        span {
          display: none;
        }
        &::before {
          content: '➔';
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: #a4a4a4;
          font-size: 20px;
          font-weight: 300;
        }
      }

      .dow {
        color: #606060;

        padding: 8px;
        font-weight: 700;
        font-size: 0.86rem;
      }
      .rdtDay {
        color: #606060;
        padding: 8px;
        font-weight: 700;
        font-size: 0.86rem;
        border-radius: 50%;
      }
      .rdtMonth {
        color: #606060;
        padding: 20px;
        font-weight: 700;
        font-size: 0.86rem;
        border-radius: 50%;
      }
      .rdtYear {
        color: #606060;
        padding: 20px;
        font-weight: 700;
        font-size: 0.86rem;
        border-radius: 50%;
      }
      .rdtOld {
        color: #0a0a0a50;
        font-weight: 400;
        font-size: 0.96rem;
      }
      .rdtNew {
        color: #0a0a0a50;
        font-weight: 400;
        font-size: 0.96rem;
      }
      .rdtActive {
        //   padding: 5px;
        background: $action-primary;
        border-radius: 50%;
        color: #0a0a0a;
        font-weight: 700;
        &::before {
          display: none;
        }
        &:hover {
          background: $action-primary;
          color: #ffffff;
        }
      }
      .rdtTimeToggle {
        background: fuchsia;
      }
    }
    .rdtMonths,
    .rdtYears {
      table {
        thead {
          tr {
            .rdtPrev {
              width: 12px;
              // display: flex;
              // width: max-content;
            }
            .rdtNext {
              width: 12px;
            }
          }
        }
      }
      table {
        tbody {
          &:first-child {
            padding-top: 30px;
          }
          tr {
            &:first-child {
              padding-top: 30px;
            }
          }
        }
      }
    }
  }
}
