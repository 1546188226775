@import '../../../assets/variable.scss';
.select-theme {
  margin-top: 20px;
}

.select-theme p {
  margin: 0 0 5px 0;
  padding: 0;
}

.radio-label {
  color: #303030;
  font-size: 14px;
  font-weight: 400;
  margin-right: 7px;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
}

.radio-input {
  margin: 0;
  visibility: hidden;
}

.radio-input:checked + span {
  width: 25px;
  height: 25px;
  border: 1px solid $black-color20;
  background-color: $action-primary;
}

.radio-input:checked + span:after {
  opacity: 1;
}

.custom-radio {
  left: -8px;
  top: 8px;
  cursor: pointer;
  width: 17px;
  height: 17px;
  border: 2px solid #9a9a9a;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  width: 25px;
  height: 25px;
  border: 1px solid $black-color20;
  background-color: $white-color;
}

/* for inner filled circle */
.custom-radio::after {
  content: '';
  width: 12px;
  height: 12px;
  background: $white-color;
  position: absolute;
  border-radius: 50%;
  top: 48%;
  left: 51%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.2s;
}
