@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');
@import '~bootstrap/scss/bootstrap';
@import 'assets/variable.scss';

a {
  display: block;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

.main-contents h1 {
  padding-left: 2.4rem;
}

.input-flex {
  display: flex;
  justify-content: space-between;
}

.heading-h1 {
  font-size: $font-size-h1;
  line-height: $line-height-h1;
  font-family: 'Open Sans';
  font-weight: 700;
}

.heading-h2 {
  font-size: $font-size-h2;
  line-height: $line-height-h2;
  font-family: 'Open Sans';
  font-weight: 700;
}

.heading-h3 {
  font-size: $font-size-h3;
  line-height: $line-height-h3;
  font-family: 'Open Sans';
  font-weight: 700;
}

.heading-h4 {
  font-size: $font-size-h4;
  line-height: $line-height-h4;
  font-family: 'Open Sans';
  font-weight: 700;
}

.heading-h5 {
  font-size: $font-size-h5;
  line-height: $line-height-h5;
  font-family: 'Open Sans';
  font-weight: 700;
}

.heading-h6 {
  font-size: $font-size-h6;
  line-height: $line-height-h6;
  font-family: 'Open Sans';
  font-weight: 700;
}

.heading-sm {
  font-size: $font-size-sm;
  line-height: $line-height-sm;
  font-family: 'Open Sans';
  font-weight: 400;
}
.heading-smb {
  font-size: $font-size-sm;
  line-height: $line-height-sm;
  font-family: 'Open Sans';
  font-weight: 700;
}

.heading-xs {
  font-size: $font-size-xs;
  line-height: $line-height-sm;
  font-family: 'Open Sans';
  font-weight: 400;
  color: $gray-color4;
}

.heading-xsb {
  font-size: $font-size-xs;
  line-height: $line-height-xs;
  font-family: 'Open Sans';
  font-weight: 700;
}
.primary-btn {
  font-size: $font-size-sm;
  line-height: $line-height-sm;
  font-family: 'Open Sans';
  font-weight: 600;
  color: $gray-color4;
  background-color: $action-primary;
  border: 1px solid transparent;
  padding: 9.5px 24px;
  border-radius: 0px;
  transition: 0.3s;
  cursor: pointer;
  white-space: nowrap;

  &:not(:disabled) {
    &:hover {
      background-color: transparent;
      border: 1px solid $action-primary;
      color: $gray-color4;
    }
  }

  &:disabled {
    background: #adadad;
  }
}
.secondary-btn {
  font-size: $font-size-sm;
  line-height: $line-height-sm;
  font-family: 'Open Sans';
  font-weight: 600;
  color: $gray-color4;
  background: rgba(10, 10, 10, 0.1);
  border: 1px solid transparent;
  padding: 9.5px 24px;
  border-radius: 0px;
  transition: 0.3s;
  cursor: pointer;
  white-space: nowrap;

  &:not(:disabled) {
    &:hover {
      background-color: transparent;
      border: 1px solid $action-primary;
      color: $gray-color4;
    }
  }

  &:disabled {
    background: #adadad;
  }
}
.danger-btn {
  font-size: $font-size-sm;
  line-height: $line-height-sm;
  font-family: 'Open Sans';
  font-weight: 600;
  color: $white-color;
  background: $action-danger;
  border: 1px solid transparent;
  padding: 9.5px 24px;
  border-radius: 0px;
  transition: 0.3s;
  cursor: pointer;
  white-space: nowrap;

  &:not(:disabled) {
    &:hover {
      background-color: transparent;
      border: 1px solid $action-primary;
      color: $gray-color4;
    }
  }

  &:disabled {
    background: #adadad;
  }
}
header {
  padding: 0px 48px;
  height: 107px;
  display: flex;
  align-items: center;
}
body {
  background-color: $background-color;
}
.body-content {
  transition: margin 0.3s ease-in-out;
}
.body-content.has-sidebar {
  margin-left: 270px;
  margin-right: 0;
}
.sidebar-mini + .mini-sidebar-margin {
  margin-left: 120px !important;
}
.linkk-otr {
  display: flex;
  align-items: center;
  gap: 0 8px;
}
.page-linkk {
  &:not(:last-child) {
    color: $black-color65;
  }
  &:last-child {
    color: $black-color65;
  }
  a {
    color: inherit;
  }
}

.show-sidebar-icon {
  padding-top: 1rem;
  cursor: pointer;
}

.showsidebar-header {
  color: #00b5d0;
  font-size: 40px;
  font-weight: 700;
  font-family: 'Open Sans';
}

.showsidebar-header span {
  color: #0a0a0a;
  font-size: 40px;
  font-weight: 700;
  font-family: 'Open Sans';
}
.custom-modal-line {
  width: calc(100% + 50px);
  transform: translateX(-30px);
}

@media (max-width: $small-screen) {
  body {
    // background-color: $white-color;
  }
}

.search-icon {
  position: absolute;
  margin-top: 1.2rem;
}

@media (max-width: 660px) {
  .body-content.has-sidebar {
    margin-left: 0;
    // margin-top: 94px;
  }
  .sidebar-mini + .mini-sidebar-margin {
    margin-left: 0 !important;
  }
  main {
    max-width: fit-content;
  }

  .sidebar-main {
    z-index: 100;
  }

  .container {
    display: block;
  }

  .table-main .table-rows {
    // margin-top: 4.5rem;
  }

  .user-name {
    display: none !important;
  }

  .caretdown {
    display: none !important;
  }

  .input-flex {
    display: flex;
    flex-direction: column;
  }

  .col-login-otr {
    width: 100% !important;
  }

  .footer-table {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    padding: 0 !important;
  }

  .pagination {
    padding-left: 5px;
  }

  .main-date {
    width: 100% !important;
  }
}

.password-wrapper {
  position: relative;
}

.password-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 15px;
  top: 70%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 40;
}

.main-date {
  width: 44%;
}

.password-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 15px;
  top: 73%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 40;
}

.outreachleader-otr label {
  font-size: $font-size-xs;
  line-height: $line-height-sm;
  font-family: 'Open Sans';
  font-weight: 700;
  color: $gray-color4;
  opacity: 0.65;
}

.select-trigger {
  font-size: $font-size-sm;
  line-height: $line-height-sm;
  font-family: 'Open Sans';
  font-weight: 400;
  color: $gray-color4;
  background-color: transparent;
  border: 1px solid #ced4da;
  padding: 8px 28px;
  border-radius: 0px;
  position: relative;
  z-index: 10;
  transition: 0.3s;
  margin-top: revert;
  cursor: pointer;
  &:focus {
    border: 1px solid $action-primary;
    outline: none;
  }
}

.caretdown-icon {
  position: absolute;
  top: 52%;
  right: 1rem;
  transform: translateY(-46%);
  pointer-events: none;
}

.select-wrapper {
  position: relative;
}

.select-options {
  position: absolute;
  // top: calc(100% + 4px);
  // left: 0;
  z-index: 100;
  width: 97%;
  background-color: #fff;
  box-shadow: 0px 0.3em 0.3em rgba(0, 0, 0, 0.1) !important;
  list-style: none;
  padding: 0;
  margin: 0;
  display: none;
  max-height: 150px;
  overflow: auto;
}

.select-options.show {
  display: block;
}

.select-options li {
  padding: 8px 16px;
  cursor: pointer;
  color: #0a0a0a;
  opacity: 0.5;
  padding-left: 30px;
  font-size: $font-size-sm;
  line-height: $line-height-sm;
  font-family: 'Open Sans';
  font-weight: 400;
}

.select-options li:hover {
  background-color: #f2f2f2;
}

.select-options li.selected {
  background-color: #e6e6e6;
}

@media (max-width: 660px) and (min-width: 500px) {
  .search-otr {
    display: flex;
    flex-direction: column;
  }

  .button-container {
    display: flex;
  }

  .search-otr-wrapper {
    width: 380px !important;
  }
}

@media (max-width: 490px) and (min-width: 400px) {
  .search-otr {
    display: flex;
    flex-direction: column;
  }

  .button-container {
    display: flex;
    flex-direction: column !important;
    // width: 98%;
    button {
      margin: 0 !important;
      margin-top: 5px;
    }
  }

  .my-button {
    display: flex;
  }

  .search-otr-wrapper {
    width: 340px !important;
  }
}

@media (max-width: 399px) {
  .search-otr {
    display: flex;
    flex-direction: column;
  }

  .button-container {
    display: flex;
    flex-direction: column !important;
    width: 98%;
  }

  .my-button {
    display: flex;
  }

  .search-otr-wrapper {
    width: 305px !important;
  }
}

.m-0-imp {
  margin: 0 !important;
}

.secondary-btns {
  font-size: $font-size-sm;
  line-height: $line-height-sm;
  font-family: 'Open Sans';
  font-weight: 600;
  color: $gray-color4;
  background: rgba(10, 10, 10, 0.1);
  border: 1px solid transparent;
  padding: 9.5px 24px;
  border-radius: 0px;
  transition: 0.3s;
  cursor: pointer;
  white-space: nowrap;
  width: 14rem;

  &:not(:disabled) {
    &:hover {
      background-color: transparent;
      border: 1px solid $action-primary;
      color: $gray-color4;
    }
  }

  &:disabled {
    background: #adadad;
  }
}

.w-imp-100 {
  .input-otr {
    width: 100% !important;
  }
}

.muted-bgr{
  background: rgba(10, 10, 10, 0.1) !important;
}
