@import '../../../assets/variable.scss';

.modal {
  .modal-dialog {
    max-width: 1110px;
  }
  .modal-sm {
    max-width: 640px;
  }
  .modal-content {
    border-radius: 16px;
    background-color: $white-color;
  }
  .modal-header {
    padding: 15px 30px;
    background-color: $white-color;
    border: none;
    border-radius: 0;
    .modal-heading {
      color: #0a0a0a;
    }
    .btn-close {
      display: none;
    }
    .close-icon-otr {
      background-color: transparent;
      padding: 0;
      border-radius: 0;
      border: none;
      box-shadow: none;
      cursor: pointer;
      svg {
        color: $black-color65;
      }
    }
  }
  .modal-content {
    border-radius: 0;
  }
  .modal-body {
    padding: 0px 20px 36px 30px;

    .input-main {
      display: flex;
      align-items: center;
      gap: 0 30px;
      width: 100%;
      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
    .input-otr {
      width: 50%;
    }
    .input {
      width: 100%;
    }
    .select-otr {
      width: 50%;
      .Label-theme {
        margin-bottom: 8px;
      }
      .css-6j8wv5-Input {
        padding: 12px 0;
      }
    }
    .action {
      display: flex;
      margin-top: 30px;
    }
  }
}

@media (max-width: 800px){
  .action{
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .action .w-50{
    width: 100% !important;
  }

  .action .secondary-btn{
    order: 2;
  }
} 