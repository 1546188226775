.custom-dropdown {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  color: #0a0a0a;
  cursor: pointer;
  white-space: nowrap;
  margin-top: 5px;
  width: 100%;
}

.custom-dropdown-toggle {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  color: #0a0a0a;
  padding: 9.5px 24px;
  cursor: pointer;
  white-space: nowrap;
  background: rgba(10, 10, 10, 0.1);
  border: 1px solid transparent;
  border-radius: 0px;
  transition: 0.3s;
  opacity: 0.5;
  // width: 10rem;
  max-width: fit-content;
}

.custom-dropdown-options {
  min-width: 10rem;
  border: none;
  margin-top: 6px;
  color: #0a0a0a;
  background: #fff;
  z-index: 100;
  position: absolute;
  top: 4rem;
  box-shadow: 0 0px 5px rgba(0, 0, 0, 0.2);
  overflow: auto;
  max-width: fit-content;
}

.custom-dropdown-options li {
  width: 100%;
  list-style: none;
  background-color: #fff;
  color: #0a0a0a;
  opacity: 0.5;
  padding-left: 1.4rem !important;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-inline: 8px;
}

ul {
  padding: 0 !important;
}

.custom-dropdown-options :hover {
  background: rgba(10, 10, 10, 0.1);
  width: 100%;
}

@media (max-width: 992px) {
  .custom-dropdown-wrapper {
    width: 100% !important;
  }
  .custom-dropdown-container {
    width: 100% !important;
  }
  .custom-dropdown-options {
    min-width: 100% !important;
    position: absolute;
    top: 7rem;
  }
  .custom-dropdown-toggle {
    min-width: 98%;
  }
}
